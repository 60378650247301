<template>
  <!-- 扫码支付弹窗 -->
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="pay-center animated faster fadeIn">
        <div class="pay-title space-between">
          <div class="row-center text-main font-lighter">
            <img src="../assets/glxt/14.png" alt="" />
            <span>确认支付</span>
          </div>
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <div class="pay-price">
          <div class="space-between">
            <span class="text-main font-normal">支付金额</span>
            <span class="font-bold">￥{{payMent.amount}}</span>
          </div>
        </div>
        <div class="price-code">
          <img :src="payMent.payment.qrcode_url" alt="" />
          <div>二维码有效期至</div>
          <div>{{payMent.failure_time}}</div>
          <div class="flex-center wx-img">
            <img src="../assets/glxt/16.png" alt="" />
            <span>扫码支付</span>
          </div>
          <div>付费即表示同意 <span class="text-blue pointer" @click="serveClick">《干纺织增值服务协议》</span></div>
        </div>
      </div>
    </div>
    <mask-serve ref="maskServe"></mask-serve>
  </div>
</template>

<script>
import maskServe from "./server.vue"
export default {
  components: {maskServe},
  props: {
    payMent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      status: false,
    };
  },

  created() {},

  mounted() {},

  methods: {
    serveClick(){
      this.$refs.maskServe.show()
    },
    hide() {
      this.status = false;
      this.$emit('hide')
    },
    show() {
      this.status = true;
    },
  },
};
</script>
<style lang="less" scoped>
.pay-center {
  width: 30.125rem;
  height: 32.8125rem;
  background: #ffffff;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 1.9375rem 1.6875rem 4.25rem 1.6875rem;
  .pay-title {
    align-items: flex-start;
    margin-bottom: 2.375rem;
    div {
      padding-top: 3px;
      img {
        width: 1.0625rem;
        height: 1.1875rem;
        margin-right: 8px;
      }
    }
  }
  .pay-price {
    width: 100%;
    padding: 0 9px 0 5px;
    margin-bottom: 2.5rem;
    div {
      width: 100%;
      height: 5.5625rem;
      background: #ffffff;
      box-shadow: -3px 0px 1.0625rem 0px rgba(0, 0, 0, 0.06);
      padding: 0 2rem;
      span:last-child {
        color: #fc6c38;
      }
    }
  }
  .price-code {
    text-align: center;
    color: #a3a3a3;
    font-size: 0.8125rem;
    img {
      width: 7.4375rem;
      height: 7.375rem;
      margin-bottom: 1rem;
    }
    .wx-img {
      margin-top: 1.8125rem;
      margin-bottom: 0.9375rem;
      img {
        width: 1rem;
        height: 1rem;
        margin-bottom: 0;
      }
      img:nth-child(2) {
        margin: 0 5px;
      }
    }
    > div:last-child {
      font-size: 0.8125rem;
      color: #666d80;
    }
  }
}
</style>
