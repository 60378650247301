<template>
	<div class="position-release">
		<div class="space-between release-heard">
			<div class="text-blue font-bold">全部职位</div>
			<div class="flex-center font-normal text-white bg-blue pointer" @click="releaseClick">
				<span class="font-light">+</span> 发布职位
			</div>
		</div>
		<div class="heard-tab row-center">
			<div class="pointer" :class="[
          heardTabIndex === index ? 'heardTabClass' : '',
          heardTabIndex === 3 && heardTabIndex === index ? 'heardCurrent' : '',
        ]" @click="headTabClick(index)" v-for="(item, index) in tabList" :key="index">
				{{ item.name }} · {{ item.num }}
			</div>
		</div>
		<div class="release-center">
			<div class="release-item row" v-for="(item, index) in itemList" :key="item.id">
				<div class="item-left pointer" @click="checkClick(index)">
					<img src="../../../assets/glxt/27.png" alt="" v-if="item.check" />
					<div v-else></div>
				</div>
				<div class="item-right one">
					<div class="right-center-heard space-between">
						<div class="center-heard-detail column">
							<div class="font-lighter font-weight-bolder">{{ item.position_name }}</div>
							<div class="row-center detail-peice">
								<span v-if="item.salary_min == '面议'">{{ item.salary_min }}</span>
								<span v-else>{{ item.salary_min }}-{{ item.salary_max }}</span>
								<div>|</div>
								<span>{{ item.experience }}</span>
								<div>|</div>
								<span>{{ item.province }}</span>
							</div>
						</div>
						<div class="center-filter column font-normal" v-if="heardTabIndex == 0">
							<div>{{ item.screen_num }}</div>
							<div>待筛选</div>
						</div>
						<div class="center-right-botton row-center" v-if="heardTabIndex == 0">
							<div class="bg-blue text-white font-normal flex-center pointer"
								@click="posClick(1, item.id)">
								职位刷新
							</div>
							<div class="bg-blue text-white font-normal flex-center pointer"
								@click="posClick(2, item.id)">
								职位置顶
							</div>
							<div class="bg-blue text-white font-normal flex-center pointer"
								@click="posClick(3, item.id)">
								职位急招
							</div>
						</div>
						<div class="center-right-botton-one row-center" v-if="heardTabIndex !== 0">
							<div class="flex-center font-normal bg-blue text-white pointer"
								:class="heardTabIndex == 2 || heardTabIndex == 3 ? 'button-no-click' : ''"
								@click="posClick(7, item.id)">
								上线
							</div>
							<div class="flex-center font-normal bg-blue text-white pointer"
								:class="heardTabIndex == 2 ? 'button-no-click' : ''" @click="posClick(4, item.id)">
								修改
							</div>
							<div class="flex-center font-normal bg-blue text-white pointer"
								@click="posClick(8, item.id)">
								删除
							</div>
						</div>
					</div>
					<div class="right-center-feet space-between">
						<div class="feet-left row-center font-normal text-main">
							<img :src="item.hr.avatar_url ?item.hr.avatar_url : avatar " alt="" />
							<div>{{ item.hr.user_name }}</div>
							<div>
								{{ item.create_time }}
								<span v-if="heardTabIndex == 0">（{{ item.failure_day }}天后下线）</span>
							</div>
						</div>
						<div class="row-center feet-right font-normal text-main" v-if="heardTabIndex == 0">
							<div class="pointer" @click="posClick(4, item.id)">修改</div>
							<div class="pointer" @click="posClick(5, item.id)">下线</div>
							<div class="pointer" @click="posClick(6, item.id)">分享</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="itemList.length == 0" class="direction no-list">
				<img src="../../../assets/glxt/45.png" class="el-no-list-img" alt="" />
				<span>什么也没有，发布职位靠谱人才马上就位</span>
			</div>
		</div>

		<div class="release-center-bottom row-center" v-if="heardTabIndex == 0">
			<div class="check-box row-center pointer" @click="checkClickAll">
				<img src="../../../assets/glxt/27.png" alt="" v-if="checkAll" />
				<div class="pointer" v-else></div>
				<span class="font-normal">全选</span>
			</div>
			<div class="text-blue font-normal flex-center pointer el-center-bottom-botton" @click="batchFlushClick">
				批量刷新职位
			</div>
			<div class="text-blue font-normal flex-center pointer el-center-bottom-botton" @click="batchOnlineClcik">
				批量下线职位
			</div>
		</div>
		<mask-model width="482px" ref="maskModel" sureContent="去认证" title="温馨提示！" :btnCancel="false"
			@submitClick="submitClick">
			<div class="mask-div-center font-normal text-main">
				系统检测到您还未进行企业认证，认证后即可对职位进行操作
			</div>
		</mask-model>
		<!-- 公共支付组件 -->
		<pay ref="payPrice" :payMent="payMent"></pay>
		<!-- 兑换组件 -->
		<exchange ref="exchange" @immediatelyPay="immediatelyPay" :titleText="titleText" :heardText="heardText"
			:contentText="contentText" :index="index" :payList="payList" :flushIds="flushObj.id"></exchange>
		<mask-model ref="share" :btnCancel="false" title="扫描二维码进行分享" width="480px">
			<div class="share-center">
				<img :src="img_code_url" alt="" />
				<div>请使用微信扫一扫功能</div>
			</div>
		</mask-model>
	</div>
</template>

<script>
	import maskModel from "../../../components/mask.vue";
	import exchange from "../../../components/exchangeOne.vue";
	import pay from "../../../components/pay.vue";
	import api from "../../../api/position";
	import userApi from "../../../api/user";
	import {
		mapState
	} from "vuex";
	export default {
		components: {
			maskModel,
			exchange,
			pay
		},
		computed: {
			// 2. 解构状态作为计算属性
			...mapState(["userDetail", "entDetail"]),
		},
		data() {
			return {
				tabList: [{
						name: "在线中",
						num: 0,
					},
					{
						name: "未上线",
						num: 0,
					},
					{
						name: "审核中",
						num: 0,
					},
					{
						name: "审核不通过",
						num: 0,
					},
				],
				heardTabIndex: 0,
				heardText: "",
				titleText: "",
				contentText: "",
				index: 0,
				// 全部选中
				checkAll: false,
				itemList: [],
				query: {
					pageSize: 100,
					page: 1,
					is_mine: 1,
					is_online: 1,
					audit_status: -1,
				},
				onlineQuery: {
					id: [], //职位ID集合(数组)，或者单个职位ID
					is_online: 0, //上下线类型，1 上线 0 下线
				},
				payList: [{
						type: 1,
						name: "纺职豆",
					},
					{
						type: 2,
						name: "置顶天数",
					},
					{
						type: 3,
						name: "支付宝",
					},
					{
						type: 4,
						name: "微信",
					},
				],
				// 用户资产信息
				accountObj: null,
				// 职位刷新数据
				flushObj: {
					id: [],
					pay_type: 2,
				},
				// 置顶和急招的相关数据
				actQuery: {
					id: "",
					start_time: "", //说明：置顶开始日期
					end_time: "", //置顶结束日期,
					pay_type: 1, //支付类型：1 纺职豆 2 置顶天数 3 支付宝 4 微信
					type: "top", //设置类型：top 置顶 recruit 急招
				},
				payMent: null,
				setTime: null,
				buyObj: null,
				img_code_url: "",
				avatar: require("@/assets/login/922028.png"),
			};
		},

		created() {
			this.posiList();
			this.userAccount();
			this.posiNum();
		},
		beforeDestroy() {
			clearInterval(this.setTime);
		},
		mounted() {},

		methods: {
			getShare(id) {
				api.getShare(id).then((res) => {
					if (res.code == 200) {
						this.img_code_url = res.data.qrcode;
						this.$refs.share.show();
					}
				});
			},
			// 轮训查看是否支付成功
			setInter() {
				this.setTime = setInterval(() => {
					userApi.searchOrder({
						order_no: this.buyObj.order_no
					}).then((res) => {
						if (res.code == 200) {
							if (res.data.pay_status == 1) {
								this.$refs.payPrice.hide();
								this.userAccount();
								clearInterval(this.setTime);
								// this.$util.msg("支付成功");
								this.$confirm('购买干纺织会员服务，支付成功！', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '关闭',
									type: 'success',
									center: true
								});
							}
						}
					});
				}, 1000);
			},
			// 获取发布职位的数量
			posiNum() {
				api.posiNum().then((res) => {
					if (res.code == 200) {
						this.tabList[0].num = res.data.online_num ? res.data.online_num : 0;
						this.tabList[1].num = res.data.offline_num ? res.data.offline_num : 0;
						this.tabList[2].num = res.data.now_audit_num ? res.data.now_audit_num : 0;
						this.tabList[3].num = res.data.no_audit_num ? res.data.no_audit_num : 0;
					}
				});
			},
			// 获取用户资产
			userAccount() {
				userApi.userAccount().then((res) => {
					if (res.code == 200) {
						this.accountObj = res.data;
					}
				});
			},
			// 批量刷新职位
			batchFlushClick() {
				if (this.userDetail.is_auth !== 1) {
					this.$refs.maskModel.show();
					return;
				}
				let idList = this.itemList.map((item) => {
					if (item.check) {
						return item.id;
					}
				});

				this.flushObj.id = idList.filter((item) => {
					return item;
				});
				if (this.flushObj.id.length == 0) {
					this.$util.msg("请勾选职位", "warning");
					return;
				}
				this.index = 1;
				if (this.accountObj && this.accountObj.refresh_num > this.flushObj.id.length) {
					this.$confirm("是否刷新这些职位?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "error",
						})
						.then(() => {
							this.flushObj.pay_type = 2;
							this.setFlush();
						})
						.catch(() => {});
					return;
				}
				this.heardText = "职位刷新";
				this.titleText = "刷新";
				this.contentText =
					"【刷新职位区】位于置顶职位下方，其他未刷新职位上方。排名越靠前，可获得更多在求职者面前的曝光机会。";
				this.payList = [{
						type: 1,
						name: "纺职豆",
					},
					{
						type: 3,
						name: "支付宝",
					},
					{
						type: 4,
						name: "微信",
					},
				];
				this.$refs.exchange.show();
			},
			// 刷新职位
			setFlush() {
				api.setFlush(this.flushObj).then((res) => {
					if (
						this.flushObj.pay_type !== 1 &&
						this.flushObj.pay_type !== 2 &&
						res.code == 200
					) {
						clearInterval(this.setTime);
						this.buyObj = res.data;
						this.setInter();
						if (this.flushObj.pay_type == 3) {
							let divForm = document.getElementsByTagName("divform");
							if (divForm.length) {
								document.body.removeChild(divForm[0]);
							}
							const div = document.createElement("divform");
							div.innerHTML = res.data.payment;
							document.body.appendChild(div);
							document.forms[0].setAttribute("target", "_blank");
							document.forms[0].submit();
							return;
						}
						this.payMent = res.data;
						this.$refs.payPrice.show();
					} else if (
						(this.flushObj.pay_type == 1 && res.code == 200) ||
						(this.flushObj.pay_type == 2 && res.code == 200)
					) {
						this.$util.msg(res.msg);
						this.posiList();
						this.userAccount();
					}
					this.flushObj.id = [];
				});
			},
			// 批量下线
			batchOnlineClcik() {
				if (this.userDetail.is_auth !== 1) {
					this.$refs.maskModel.show();
					return;
				}
				this.onlineQuery.is_online = 0;
				let idList = this.itemList.map((item) => {
					if (item.check) {
						return item.id;
					}
				});

				this.onlineQuery.id = idList.filter((item) => {
					return item;
				});
				if (this.onlineQuery.id.length == 0) {
					this.$util.msg("请勾选职位", "warning");
					return;
				}
				this.$confirm("是否批量下线这些职位?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error",
					})
					.then(() => {
						this.setOnline();
					})
					.catch(() => {});
			},
			// 设置上下线
			setOnline() {
				api.setOnline(this.onlineQuery).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg);
						this.posiList();
						this.posiNum();
					}
					this.onlineQuery.id = [];
				});
			},
			// 获取职位列表
			posiList() {
				const loading = this.$loading({
					lock: true,
					text: "加载中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				api.posiList(this.query).then((res) => {
					let data = res.data.data;
					if (data.length > 0) {
						data.forEach((element) => {
							element.check = false;
						});
					}
					this.itemList = data;
					loading.close();
				});
			},
			headTabClick(index) {
				this.heardTabIndex = index;
				if (index == 0) {
					this.query.is_online = 1;
					this.query.audit_status = -1;
				} else if (index == 1) {
					this.query.is_online = 0;
					this.query.audit_status = -1;
				} else if (index == 2) {
					this.query.is_online = -1;
					this.query.audit_status = 0;
				} else {
					this.query.is_online = -1;
					this.query.audit_status = 2;
				}
				this.posiList();
			},
			// 发布职位
			releaseClick() {
				this.$router.push({
					path: "/layout/position"
				});
			},
			// 选中
			checkClick(index) {
				if (this.itemList[index].check) {
					this.itemList[index].check = false;
					this.checkAll = false;
				} else {
					this.itemList[index].check = true;
					for (var i = 0; i < this.itemList.length; i++) {
						if (!this.itemList[i].check) {
							this.checkAll = false;
							return;
						} else {
							this.checkAll = true;
						}
					}
				}
			},
			// 全选
			checkClickAll() {
				this.checkAll = !this.checkAll;
				if (this.checkAll) {
					for (var i = 0; i < this.itemList.length; i++) {
						this.itemList[i].check = true;
					}
				} else {
					for (var i = 0; i < this.itemList.length; i++) {
						this.itemList[i].check = false;
					}
				}
			},
			// 操作
			posClick(index, id) {
				if (this.entDetail.is_auth !== 1) {
					this.$refs.maskModel.show();
					return;
				}
				this.index = index;
				if (index == 1) {
					this.flushObj.id = [];
					if (this.accountObj && this.accountObj.refresh_num > 0) {
						7;
						this.$confirm("是否刷新该职位?", "提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "error",
							})
							.then(() => {
								this.flushObj.pay_type = 2;
								this.flushObj.id.push(id);
								this.setFlush();
							})
							.catch(() => {});
						return;
					}
					this.flushObj.id.push(id);
					this.heardText = "职位刷新";
					this.titleText = "刷新";
					this.contentText =
						"【刷新职位区】位于置顶职位下方，其他未刷新职位上方。排名越靠前，可获得更多在求职者面前的曝光机会。";
					this.payList = [{
							type: 1,
							name: "纺职豆",
						},
						{
							type: 3,
							name: "支付宝",
						},
						{
							type: 4,
							name: "微信",
						},
					];
					this.$refs.exchange.show();
				} else if (index == 2) {
					this.actQuery.id = id;
					this.actQuery.type = "top";
					this.payList = [{
							type: 1,
							name: "纺职豆",
						},
						{
							type: 2,
							name: "置顶天数",
						},
						{
							type: 3,
							name: "支付宝",
						},
						{
							type: 4,
							name: "微信",
						},
					];
					this.heardText = "职位置顶";
					this.titleText = "置顶";
					this.contentText =
						"【置顶职位区】位于首页置顶区，获得顶级曝光，让更多人才快速发现您的职位！";
					this.$refs.exchange.show();
				} else if (index == 3) {
					this.actQuery.id = id;
					this.actQuery.type = "recruit";
					this.payList = [{
							type: 1,
							name: "纺职豆",
						},
						{
							type: 2,
							name: "急招天数",
						},
						{
							type: 3,
							name: "支付宝",
						},
						{
							type: 4,
							name: "微信",
						},
					];
					this.heardText = "职位急招";
					this.titleText = "急招";
					this.contentText =
						"【急招职位区】位于首页急招专区，提升专项曝光度，人才更快就位。";
					this.$refs.exchange.show();
				} else if (index == 4) {
					if (this.heardTabIndex == 2) return;
					this.$router.push({
						path: "/layout/position",
						query: {
							id: id
						}
					});
				} else if (index == 5) {
					this.$confirm("确认是否下线该职位?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "error",
						})
						.then(() => {
							this.onlineQuery.is_online = 0;
							this.onlineQuery.id.push(id);
							this.setOnline();
						})
						.catch(() => {});
				} else if (index == 6) {
					this.getShare(id);
				} else if (index == 7) {
					if (this.heardTabIndex == 2 || this.heardTabIndex == 3) return;
					this.$confirm("是否上线该职位?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						})
						.then(() => {
							this.onlineQuery.is_online = 1;
							this.onlineQuery.id.push(id);
							this.setOnline();
						})
						.catch(() => {});
				} else if (index == 8) {
					this.$confirm("删除后不能恢复，是否确认删除？?", "确定删除当前职位", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "error",
						})
						.then(() => {
							this.delPosi(id);
						})
						.catch(() => {});
				}
			},
			// 删除职位
			delPosi(id) {
				api.delPosi({
					id
				}).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg);
						this.posiList();
						this.posiNum();
					}
				});
			},
			// 去认证
			submitClick() {
				this.$router.push({
					path: "/layout/account"
				});
			},
			// 设置急招，置顶
			setActClick() {
				api.setAct(this.actQuery).then((res) => {
					if (
						this.actQuery.pay_type !== 1 &&
						this.actQuery.pay_type !== 2 &&
						res.code == 200
					) {
						clearInterval(this.setTime);
						this.buyObj = res.data;
						this.setInter();
						if (this.actQuery.pay_type == 3) {
							let divForm = document.getElementsByTagName("divform");
							if (divForm.length) {
								document.body.removeChild(divForm[0]);
							}
							const div = document.createElement("divform");
							div.innerHTML = res.data.payment;
							document.body.appendChild(div);
							document.forms[0].setAttribute("target", "_blank");
							document.forms[0].submit();
							return;
						}
						this.payMent = res.data;
						this.$refs.payPrice.show();
					} else {
						if (res.code == 200) {
							this.$util.msg(res.msg);
							this.userAccount();
						}
					}
				});
			},
			//立即支付
			immediatelyPay(item) {
				if (this.index == 2 || this.index == 3) {
					this.actQuery.end_time = item.end_time;
					this.actQuery.start_time = item.start_time;
					this.actQuery.pay_type = item.payindex;
					this.setActClick();
				} else {
					this.flushObj.pay_type = item.payindex;
					this.setFlush();
				}
			},
		},
	};
</script>
<style lang="less" scoped>
	.position-release {
		width: 100%;
		padding: 19px 147px;

		.release-heard {
			margin-bottom: 20px;

			div:last-child {
				border-radius: 4px;
				width: 98px;
				height: 34px;
			}
		}

		.heard-tab {
			height: 39px;
			background: #ffffff;
			padding-left: 62px;

			div {
				margin-right: 60px;
				color: #555555;
				font-size: 15px;
				height: 100%;
				line-height: 2.4375rem;
				position: relative;
				margin-right: 60px;

				&:after {
					position: absolute;
					content: "";
					width: 0;
					height: 0;
					border-bottom: 4px solid #126bf9;
					left: 0;
					bottom: 0;
					transition: 0.3s;
				}
			}

			.heardTabClass {
				color: #126bf9;

				&:after {
					width: 4rem;
				}
			}

			.heardCurrent {
				&:after {
					width: 96px;
				}
			}
		}

		.release-center {
			margin-top: 10px;
			margin-bottom: 29px;

			.release-item {
				width: 100%;
				background: #fff;
				padding: 0 40px 0 0px;
				margin-bottom: 10px;

				.item-left {
					margin-right: 20px;
					padding-top: 21px;
					padding-left: 30px;

					img {
						width: 14px;
						height: 14px;
					}

					div {
						width: 14px;
						height: 14px;
						background: #f3f4f6;
						border: 1px solid #d2d5dc;
						border-radius: 2px;
					}
				}

				.item-right {
					width: 100%;

					.right-center-heard {
						width: 100%;
						height: 95px;
						border-bottom: 1px solid #f0f0f0;

						.center-heard-detail {
							justify-content: center;
							color: #222;

							.detail-peice {
								margin-top: 10px;
								color: #757575;
								font-size: 14px;

								span:first-child {
									color: #fd7240;
								}

								div {
									margin: 0 6px;
								}
							}
						}

						.center-filter {
							align-items: center;
							justify-content: center;
							color: #757575;

							div:first-child {
								margin-bottom: 10px;
							}
						}

						.center-right-botton {
							div {
								border-radius: 3px;
								width: 87px;
								height: 25px;
							}

							div:nth-child(2) {
								margin: 0 20px;
							}
						}

						.center-right-botton-one {
							div {
								width: 58px;
								height: 25px;
								border-radius: 3px;
							}

							div:nth-child(2) {
								margin: 0 19px;
							}

							.button-no-click {
								background: #757575;
							}
						}
					}

					.right-center-feet {
						width: 100%;
						height: 60px;
						padding-right: 59px;

						.feet-left {
							img {
								width: 20px;
								height: 20px;
								border-radius: 50%;
								margin-right: 13px;
							}

							div:last-child {
								color: #757575;
								margin-left: 20px;
							}
						}

						.feet-right {
							div:nth-child(2) {
								margin: 0 48px;
							}
						}
					}
				}
			}

			.no-list {
				justify-content: center;
				font-weight: 400;
				color: #bfbfbf;
				font-size: 14px;

				.el-no-list-img {
					width: 163px;
					height: 145px;
					margin-bottom: 23px;
				}
			}
		}

		.release-center-bottom {
			width: 100%;
			height: 48px;
			background: #ffffff;
			padding-left: 30px;

			.check-box {
				margin-right: 20px;
				color: #222;

				img {
					width: 14px;
					height: 14px;
					margin-right: 10px;
				}

				div {
					width: 14px;
					height: 14px;
					background: #f3f4f6;
					border: 1px solid #d2d5dc;
					border-radius: 2px;
					margin-right: 10px;
				}
			}

			.el-center-bottom-botton {
				width: 109px;
				height: 24px;
				border: 1px solid #126bf9;
				border-radius: 12px;
				margin-right: 10px;
			}
		}

		.mask-div-center {
			margin-top: 17px;
			margin-bottom: 31px;
			padding-left: 30px;
		}

		.share-center {
			width: 100%;
			margin-top: 21px;
			margin-bottom: 40px;
			text-align: center;
			color: #999999;
			font-size: 14px;

			img {
				width: 136px;
				height: 136px;
				margin-bottom: 11px;
			}
		}
	}
</style>
